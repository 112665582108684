import { React } from "react";
import styles from "./Header.module.css";
import Popover from '@mui/material/Popover';
import LogoutUserButton from "../Auth/LogoutUserButton";

function AccountPopover({anchorEl, handlePopoverClose}) {

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={styles.AccountPopover}>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <LogoutUserButton />
            </Popover>
        </div>
    );
};

export default AccountPopover;
