import { React } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import ReactMarkdown from 'react-markdown';
import styles from "./Search.module.css";
import Link from '@mui/material/Link';


function DynamicAccordion({ items }) {
    const analytics = getAnalytics();

    // Check is items an array.
    if (!Array.isArray(items)) {
        console.error("Error items is not array.");
        return null;
    }

    // Handle accordion change
    const handleAccordionChange = (isExpanded, item) => {
        if (isExpanded) {
            logEvent(analytics, 'accordion-expand', {
                filename: item.original_filename
            });
        }
    };

    return (
        <div>
            {items.length === 0 && (
                <div className={styles.resultsSkeleton}>
                    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                </div>)}
            {items.map((item, index) => (
                <Accordion
                    key={index}
                    onChange={(event, isExpanded) => handleAccordionChange(isExpanded, item)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <div className={styles.accordionHeader} >
                            <h2>{item.original_filename}</h2>
                            {item.file_summary === "" && (
                                <CircularProgress className={styles.circularProgress} />
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordionDetailsDiv}>
                        <ReactMarkdown>{item.file_summary}</ReactMarkdown>
                        <Link href={`https://storage.cloud.google.com/vergabesuche-evergabe-raw-pdf-dump/${item.gcs_filename}`} >Link zum Originaldokument</Link>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default DynamicAccordion;
