import React, { useState, useEffect } from 'react';
import { auth } from "./components/Firebase/FirebaseConfig.jsx";
import { getAnalytics, setUserId } from "firebase/analytics";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import NotFound from "./NotFound"
import './styles/App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer.jsx';
import LandingPage from './LandingPage.jsx';
import DataAgb from './components/Footer/DataAndTerms.jsx';
import Impressum from './components/Footer/Impressum.jsx';


const wakeupBackend = async () => {
  const services = [
    'https://tender-import-be-vpom7uoscq-ew.a.run.app/wakeup',
  ];

  try {
    await Promise.all(services.map(url => fetch(url, { method: 'GET' })));
    console.log('Good morning, be services are awake.');
  } catch (error) {
    console.error('Error warming up services:', error);
  }
};

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [userName, setUserName] = useState(false);

  const analytics = getAnalytics();

  useEffect(() => {
    wakeupBackend();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        console.log("User signed in");
        console.log(user);
        setSignedIn(true);
        setUserName(user.email);
        setUserId(analytics, user.uid);
      } else {
        console.log("No user signed in");
        setSignedIn(false);
        setUserName(null);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [analytics]);


  return (
    <Router>
      <div className="app-main">
        <Header signedIn={signedIn} userName={userName} />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/data-agb" element={<DataAgb />} />
          <Route path="/impressum" element={<Impressum />} />
          {/* <Route component={<NotFound />} /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
