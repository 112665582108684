import { React, useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import DynamicAccordion from "./SearchResultsAccordion";
import styles from "./Search.module.css";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "../Firebase/FirebaseConfig.jsx";
import { getAnalytics, logEvent } from "firebase/analytics";
import einfachVergabeLogo from "./media/einfach-vergabe-logo-vektor.png"
import SearchBar from "./SearchBar.jsx";
import SearchSubmitButton from "./SearchSubmitButton.jsx";
import MetaSummaryCard from "./MetaSummaryCard.jsx";

function Search() {
  const [tenderIdQuery, setTenderIdQuery] = useState("");
  const [searchQueryResponse, setSearchQueryResponse] = useState(null);
  const [documentIdsToProcess, setDocumentIdsToProcess] = useState([]);
  const [accordionData, setAccordionData] = useState({}); // Store data per firestore_document_id
  const [pendingDocumentIds, setPendingDocumentIds] = useState([]); // Store pending document IDs
  const [user] = useAuthState(auth);
  const [unauthSearchTriggered, setUnauthSearchTriggered] = useState(null); // Check for query button click
  const [invalidSearchTriggered, setInvalidSearchTriggered] = useState(null); // Check for query button click
  const [authSearchTriggered, setAuthSearchTriggered] = useState(null); // Check for query button click
  const [loading, setLoading] = useState(false);
  const searchDivRef = useRef(null);
  const [tenderBaseData, setTenderBaseData] = useState(null);
  const [tenderMetaSummary, setTenderMetaSummary] = useState(null);

  const accordionItems = Object.values(accordionData); // Get values for accordion

  // Handle input change of Search Box
  const handleInputChange = (event) => {
    setTenderIdQuery(event.target.value);
  };

  // Parse the JSON string from tender-import-be service into a JavaScript object and extract firestore document ids.
  function extractFirestoreDocumentId(resultData) {
    const dataObject = JSON.parse(resultData);
    return dataObject.firestore_document_ids;
  };

  // Parse the JSON string from tender-import-be service into a JavaScript object and extract  firestore document ids.
  function extractOriginalFilenames(resultData) {
    const dataObject = JSON.parse(resultData);
    return dataObject.original_filenames;
  };

  // Parse the JSON string from tender-import-be service into a JavaScript object and extract firestore document ids.
  function extractGcsFilenames(resultData) {
    const dataObject = JSON.parse(resultData);
    return dataObject.gcs_filenames;
  };

  // Function to validate login status before actually handling query
  const handleSearchAuth = async () => {
    setUnauthSearchTriggered(null);
    setInvalidSearchTriggered(null);
    const analytics = getAnalytics();
    console.log("Search Triggered");
    if (user) {
      logEvent(analytics, 'auth-search');
      setAuthSearchTriggered(true);
      setLoading(true);
      setSearchQueryResponse(null); // Clear result on error
      setTenderBaseData(null);
      setTenderMetaSummary(null);
      handleSearch(); // Call the handleSearch function if user is logged in
    } else {
      logEvent(analytics, 'unauth-search');
      setUnauthSearchTriggered(true);
      console.log("User is not logged in.");
    }
  };

  // Function to call tender-import-be service with tender ID requested by user
  // Result changes searchQueryResponse state which triggers periodic check for valid summary cache
  const handleSearch = async () => {
    console.log("Handling Search for: ", tenderIdQuery);
    console.log("User: ", user);

    await setAccordionData({}); // Clear accordion data on search

    try {
      console.log("Sending request...");
      const formData = new FormData();
      formData.append("tenderId", tenderIdQuery);

      const response = await fetch("https://tender-import-be-vpom7uoscq-ew.a.run.app/tenderQuery", {
        method: 'POST',
        body: formData,
      });

      // Check for 400 status code
      if (response.status === 400) {
        console.error('Inputted tender ID is invalid (400):' + tenderIdQuery);
        setSearchQueryResponse(null); // Clear result on error
        setInvalidSearchTriggered(true); // Show invalid search warning
        setLoading(false);
        setAccordionData({}); // Clear accordion data
        setAuthSearchTriggered(false);
        return; // Stop further processing
      }

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }


      // Update search query state with response data
      const responseData = await response.text();
      setSearchQueryResponse(responseData);

    } catch (error) {
      console.error('Search request error:', error.message);
      setSearchQueryResponse(null); // Clear result on error
      setAccordionData({}); // Clear accordion data
      setLoading(false);
    }
  };

  // Triggered after searchQueryResponse state is filled
  // Extracts Firestore IDs to be processed and updates states accordingly
  useEffect(() => {
    if (searchQueryResponse) {
      const documentIdsToProcessUpdated = extractFirestoreDocumentId(searchQueryResponse);
      setDocumentIdsToProcess(documentIdsToProcessUpdated);
      setPendingDocumentIds(documentIdsToProcessUpdated);
      setAccordionData({}); // Clear existing accordion data
    }
  }, [searchQueryResponse]); // This useEffect runs when searchQueryResponse updates

  // Function to pre fill accordion  data for empty render of filenames
  const preFillAccordionData = useCallback((responseData) => {
    console.log("Pre-Pre-populating accordion data...");
    console.log("be response data: ", responseData);
    console.log("doc ids to process: ", documentIdsToProcess);

    if (responseData) {
      // pre populate accordion data with ids and empty content for render
      const updatedAccordionData = {};
      const documentOriginalFilenames = extractOriginalFilenames(responseData);
      const documentGcsFilenames = extractGcsFilenames(responseData);

      const accordionDefaultValues = {
        "file_summary": "",
        "firestore_document_id": "",
        "gcs_filename": "",
        "now_ts": "",
        "original_filename": "",
        "tender_id": "671860",
        "unix_ts": 0
      };

      documentIdsToProcess.forEach((key, index) => {
        updatedAccordionData[key] = { ...accordionDefaultValues };  // Spread operator to copy defaultValues
        updatedAccordionData[key].firestore_document_id = key;
        updatedAccordionData[key].original_filename = documentOriginalFilenames[documentGcsFilenames[index]];
      });

      setAccordionData(updatedAccordionData);
    }
  }, [documentIdsToProcess]);

  // useEffect call Accordion prefill on chage of searchQueryResponse and documentIdsToProcess
  useEffect(() => {
    if (documentIdsToProcess.length > 0) {
      preFillAccordionData(searchQueryResponse);
    }
  }, [documentIdsToProcess, searchQueryResponse, preFillAccordionData]);

  // Triggered by change in pendingDocumentIds and accordionData
  // Iterates over pending document IDs checks for existing cache periodically, if cache exists, updates accordion data
  useEffect(() => {
    // Only run if there are pending IDs
    if (pendingDocumentIds.length > 0) {
      let successfulFetchIds = []; // Store successful fetch IDs
      // const successfulFetchIdsSession = [...successfulFetchIds];

      // Fetch results from results fetcher service
      const fetchResultLoop = async () => {
        const updatedAccordionData = { ...accordionData }; // Make a copy to update based on result fetcher response

        console.log("Pending document IDs: ", pendingDocumentIds);

        // Looping over pending document IDs 
        for (const firestoreDocumentId of pendingDocumentIds) {

          try {
            const formData = new FormData();
            formData.append("firestore_document_id", firestoreDocumentId);

            const response = await fetch("https://vergabesuche-results-fetcher-service-vpom7uoscq-ew.a.run.app/fetchResult", {
              method: 'POST',
              body: formData,
            });

            if (!response.ok) {
              throw new Error(`Fetch result error: ${response.status} - ${response.statusText}`);
            }

            let newResult = await response.text();

            // update accordionData state with fetched data added
            updatedAccordionData[firestoreDocumentId] = JSON.parse(newResult);

            // Only remove from pendingDocumentIds if summary is not empty
            if (!(JSON.parse(newResult).file_summary === "")) {
              successfulFetchIds.push(firestoreDocumentId);
              console.log("Sucessfully fetched: ", successfulFetchIds)
            } else {
              console.log("Failed to fetch: ", firestoreDocumentId)
              newResult = JSON.parse(newResult);
              newResult.file_summary = "Dieses Dokument konnte nicht analysiert werden. Bitte versuchen Sie es erneut.";
              updatedAccordionData[firestoreDocumentId] = newResult;
              successfulFetchIds.push(firestoreDocumentId);
            };

          } catch (error) {
            console.error('Fetch result error:', error.message);
          }

        };

        // Update accordion data state with fetched data added
        setAccordionData(updatedAccordionData);

        // Filter out IDs that were successfully fetched
        setPendingDocumentIds(pendingDocumentIds.filter(id => !successfulFetchIds.includes(id)));

        // If all IDs have been fetched end loop
        if (pendingDocumentIds.length === 0) {
          console.log("Fetch Result ended - No IDs to fetch");
          return () => clearInterval(interval);
        }
      };

      // Invoke fetch results loop periodically
      const interval = setInterval(fetchResultLoop, 3000);

      return () => clearInterval(interval);

    } else {
      // Code if no pending document ids
      // setLoading(false);
      console.log("No pending document ids");
      return () => { };
    };

  }, [documentIdsToProcess, accordionData, pendingDocumentIds]); // Re-run if there are pending IDs and new accordion data


  // Use effect to fetch meta summary and tender base info results via periodic fetch loop
  useEffect(() => {
    const fetchMetaSummaryData = async () => {
      if (tenderBaseData === null || tenderMetaSummary === null) {
        try {
          const formData = new FormData();
          formData.append("tender_id", tenderIdQuery);

          const summaryResponse = await fetch("https://vergabesuche-results-fetcher-service-vpom7uoscq-ew.a.run.app/fetchMetaSummary", {
            method: 'POST',
            body: formData,
          });

          if (summaryResponse.ok) {
            setTenderMetaSummary(await summaryResponse.json());
          }

          // Fetch tenderBaseData cache
          const baseDataResponse = await fetch("https://vergabesuche-results-fetcher-service-vpom7uoscq-ew.a.run.app/fetchBaseData", {
            method: 'POST',
            body: formData,
          });

          if (baseDataResponse.ok) {
            setTenderBaseData(await baseDataResponse.json()); // Await the response.json()
          }

          // If both caches are filled, stop the loop
          if (tenderMetaSummary && tenderBaseData) {
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Fetch meta summary data error:', error.message);
        }
      }
    };

    const interval = setInterval(fetchMetaSummaryData, 3000); // Adjust interval as needed
    return () => clearInterval(interval);
  }, [tenderIdQuery, searchQueryResponse, tenderBaseData, tenderMetaSummary]
  );



  // Triggered on change of pendingDocumentIds
  // Ending loading button state once pendingDocumentIds is empty
  useEffect(() => {
    if (pendingDocumentIds.length === 0) {
      setLoading(false);
    }
  }, [pendingDocumentIds]);

  // LayoutEffect to handle scroll down after search was triggered
  useLayoutEffect(() => {
    if (authSearchTriggered && searchDivRef.current) {
      searchDivRef.current.scrollIntoView({
        block: 'start',
        inline: 'nearest'
      });
    }
  }, [authSearchTriggered]);

  // Updated accordion rendering
  const accordion = authSearchTriggered ? (
    <div className="results-accordion-div">
      <DynamicAccordion items={accordionItems} />
    </div>
  ) : null;

  // Updated login message rendering
  const loginMessage = unauthSearchTriggered ? (
    <div className="login-message-div">
      <p>Login required to search</p>
    </div>
  ) : null;

  // Updated login message rendering
  const invalidQueryMessage = invalidSearchTriggered ? (
    <div className="login-message-div">
      <p>Invalid tender ID</p>
    </div>
  ) : null;

  const metaSummaryCard = authSearchTriggered ? (
    <div className="meta-summary-card-div">
      <MetaSummaryCard tenderSummary={tenderMetaSummary} tenderBaseData={tenderBaseData} />
    </div>
  ) : null;

  return (
    <div ref={searchDivRef} className={styles.searchDiv}>
      <div className={styles.searchLogoDiv}>
        <img src={einfachVergabeLogo} alt="" />
      </div>
      <div className={styles.searchBarDiv}>
        <SearchBar onChange={handleInputChange} />
      </div>
      <SearchSubmitButton handleSearchAuth={handleSearchAuth} loading={loading} />
      <div className={styles.searchResultDiv}>
        {metaSummaryCard}
        {accordion}
        {loginMessage}
        {invalidQueryMessage}
      </div>
    </div>
  );
}

export default Search;