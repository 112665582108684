import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBXuLIwOMVaGwF-xTVBarPucV3nOPKJEGQ",
  authDomain: "vergabesuche.firebaseapp.com",
  projectId: "vergabesuche",
  storageBucket: "vergabesuche.appspot.com",
  messagingSenderId: "163697526117",
  appId: "1:163697526117:web:67ba8c737a11127a0acd8c",
  measurementId: "G-HP2CXVZ7ST"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth, analytics };