import { React } from "react"
import { auth } from "../Firebase/FirebaseConfig.jsx";
import styles from "./Auth.module.css";
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from "firebase/auth";


function LogoutUserButton() {

    const handleClick = async () => {
        console.log("Logout request");
        try {
            await signOut(auth);
            console.log("User signed out successfully");
            // You can also redirect the user or show a message after logout
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className={styles.logoutUserButtonDiv}>
            <button className={styles.logoutUserButton} onClick={handleClick}>
                <div className={styles.logoutUserButtonContentDiv}>
                    <div className={styles.logoutUserButtonIconDiv}>
                        <LogoutIcon />
                    </div>
                    <span className={styles.logoutUserButtonContent}>Sign me out!</span>
                </div>
            </button>
        </div>
    );
}

export default LogoutUserButton;
