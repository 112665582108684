import { React, useState, useRef } from "react";
import Link from '@mui/material/Link';
import styles from "./LandingInstructions.module.css";
import TooltipDialog from "./TooltipDialog";
import { getAnalytics, logEvent } from "firebase/analytics";


function LandingInstructions() {
    const [open, setOpen] = useState(false);
    const tooltipRef = useRef(null);
    const analytics = getAnalytics();

    const handleClickOpen = () => {
        setOpen(true);
        logEvent(analytics, 'open-id-tooltip');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTooltipClick = () => {
        handleClickOpen();
    };

    return (
        <div className={styles.LandingInstructionsDiv}>
            <TooltipDialog open={open} onClose={handleClose} />
            <div className={styles.InstructionDiv}>
                <p className={styles.InstructionWithTooltip}>1. Ausschreibungs ID von evergabe-online.de kopieren.</p>
                <div className={styles.TooltipDiv} onClick={handleTooltipClick} ref={tooltipRef} handleClose={handleClose}>
                    <Link sx={{
                        color: '#01204E',
                        fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        fontWeight: 'bold',
                        fontSize: '.8rem',
                        textDecoration: 'underline',
                        lineHeight: 1,
                        display: 'block',
                    }}>
                        Wo finde ich die Ausschreibungs ID?
                    </Link>
                </div>
            </div>
            <div className={styles.InstructionDiv}>
                <p className={styles.InstructionRegular}>2. ID in einfach-vergabe Suchleiste eingeben.</p>
            </div>
            <div className={styles.InstructionDiv}>
                <p className={styles.InstructionRegular}>3. Blitzschnelle Sichtung der Vergabedokumente mithilfe der einfach-vergabe KI.</p>
            </div>
        </div>
    );
};



export default LandingInstructions;
