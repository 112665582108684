import React from 'react';
import Search from "./components/Search/Search";
import LandingInstructions from './components/LandingInstructions/LandingInstructions.jsx';

function LandingPage() {
    return (
        <div>
            <LandingInstructions />
            <Search />
        </div>
    );
}

export default LandingPage;
