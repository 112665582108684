import { React, useState, useEffect } from "react";
import { auth } from "../Firebase/FirebaseConfig.jsx";
import { signInWithEmailAndPassword } from "firebase/auth";
import styles from "./Auth.module.css";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function EmailLoginFormDialog({ openDialog, handleCloseCallback, handleSignupCallback }) {
    const [open, setOpen] = useState(openDialog);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // check for user email verification
            if (!user.emailVerified) {
                setError("Please verify your email before logging in.");
                await auth.signOut();
                return;
            }

            console.log("User logged in successfully via email!");
            // Handle successful sign-in (e.g., redirect to a different page)
        } catch (err) {
            setError(err.message);
        }
    };

    const handleClose = () => {
        setOpen(false);
        handleCloseCallback(false)
    };

    const handleSignup = () => {
        setOpen(false);
        handleSignupCallback(true)
        console.log("Sign Up clicked");
    };


    return (
        <div className={styles.LoginDialogDiv}>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
            >
                <DialogTitle>Login with your Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please login with your email and password.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSignup}>Create an account</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Login</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default EmailLoginFormDialog;
