import { React } from "react";
import styles from "./LandingInstructions.module.css";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import tooltipScreen from "./media/vergabeid-screen.png"


function TooltipDialog({ open, onClose }) {
    
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ausschreibungs ID finden"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        1. <a href="https://www.evergabe-online.de/search.html?5" rel="noreferrer" target="_blank">evergabe-online.de/search</a> besuchen.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        2. Gewünschte Ausscreibung auswählen.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        3. 6-stellige, numerische Ausschreibungs ID aus URL kopieren:
                        <div className={styles.TooltipScreenDiv}>
                            <img src={tooltipScreen} alt="" />
                        </div>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        4. In einfach-vergabe.de Suchleiste einsetzen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Zurück</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default TooltipDialog;
