import { React, useState } from "react"
import styles from "./Auth.module.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailLoginFormDialog from "./EmailLoginFormDialog";
import EmailSignupFormDialog from "./EmailSignupFormDialog";

function EmailSignInButton() {
    const [openDialog, setOpenDialog] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [loginAccount, setLoginAccount] = useState(false);

    const handeClick = () => {
        console.log("Email Sign in requested");
        setOpenDialog(true);
        setLoginAccount(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCreateAccount = () => {
        console.log("Email Sign up requested");
        setLoginAccount(false);
        setCreateAccount(true);
    };

    const handleLogin = () => {
        console.log("Email Login requested");
        setCreateAccount(false);
        setLoginAccount(true);
    };

    console.log("openDialog: " + openDialog);
    console.log("createAccount: " + createAccount);

    return (
        <div>
            {loginAccount && <EmailLoginFormDialog openDialog={openDialog} handleCloseCallback={handleClose} handleSignupCallback={handleCreateAccount}/>}
            {createAccount && <EmailSignupFormDialog openDialog={openDialog} handleCloseCallback={handleClose} handleLoginCallback={handleLogin} />}
            <button className={styles.gsiMaterialButton} onClick={() => handeClick()}>
                <div className={styles.gsiMaterialButtonState}></div>
                <div className={styles.gsiMaterialButtonContentWrapper}>
                    <div className={styles.gsiMaterialButtonIcon}>
                        <AccountCircleIcon />
                    </div>
                    <span className={styles.gsiMaterialButtonContents}>Email Sign in</span>
                </div>
            </button>
        </div>
    );
}

export default EmailSignInButton;
