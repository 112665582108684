import { React } from "react";
import styles from "./Footer.module.css";
import { NavLink } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";


function FooterLinks() {
    const analytics = getAnalytics();

    return (
        <div className={styles.FooterLinksDiv}>
            <div className={styles.siteLinkDiv}>
                <NavLink
                    className={styles.siteLink}
                    component="button"
                    variant="body1"
                    to="/impressum"
                    onClick={() => {
                        logEvent(analytics, 'impressum-clicked');
                        console.info("Mehr zu Ausschreibungs Vergaben clicked");
                    }}
                >
                    Impressum
                </NavLink>
            </div>
            <div className={styles.siteLinkDiv}>
                <NavLink
                    className={styles.siteLink}
                    component="button"
                    variant="body1"
                    to="/data-agb"
                    onClick={() => {
                        logEvent(analytics, 'data-and-agb-clicked');
                        console.info("data and agb clicked");
                    }}
                >
                    Datenschutz & AGB
                </NavLink>
            </div>
        </div>
    );
};

export default FooterLinks;
