import { React, useState } from "react"
import styles from "./Auth.module.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountPopover from "../Header/AccountPopover";


function SignedInUserButton({ userName }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        console.log("User already signed in.");
        console.log(userName);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <button className={styles.gsiMaterialButton} onClick={handleClick}>
                <div className={styles.gsiMaterialButtonState}></div>
                <div className={styles.gsiMaterialButtonContentWrapper}>
                    <div className={styles.gsiMaterialButtonIcon}>
                        <AccountCircleIcon />
                    </div>
                    <span className={styles.gsiMaterialButtonContents}>Signed in as {userName}</span>
                </div>
            </button>
            <AccountPopover anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} />
        </div>
    );
}

export default SignedInUserButton;
