import { React } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Import Grid for layout
import Skeleton from '@mui/material/Skeleton';
import ReactMarkdown from 'react-markdown';


import styles from "./Search.module.css";


function MetaSummaryCard({ tenderSummary, tenderBaseData }) {
    console.log("tenderSummary", tenderSummary);
    console.log("tenderBaseData", tenderBaseData);
    return (
        <div className={styles.metaSummaryCard}><Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Grid container spacing={2}> {/* Grid for horizontal layout */}
                    <Grid item xs={6}> {/* First div: 50% width */}
                        <h2>Ausschreibungs Zusammenfassung</h2>
                        {tenderSummary === null ? (
                            <div className={styles.resultsSkeleton}>
                                <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                            </div>
                        ) : (
                            <div className={styles.metaSummaryResultDiv}>
                                <ReactMarkdown>{tenderSummary.tender_meta_summary}</ReactMarkdown>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={6}> {/* Second div: 50% width */}
                        <h2>Ausschreibungs Stammdaten</h2>
                        {tenderBaseData === null ? (
                            <div className={styles.resultsSkeleton}>
                                <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
                            </div>
                        ) : (
                            <div className={styles.tenderBaseDataResultDiv}>
                                <h3>Bewerbungsfrist:</h3>
                                <ReactMarkdown>{tenderBaseData.application_due_date}</ReactMarkdown>
                                <h3>Ausfuehrungsort:</h3>
                                <ReactMarkdown>{tenderBaseData.place_of_fulfillment}</ReactMarkdown>
                                <h3>Auftraggeber:</h3>
                                <ReactMarkdown>{tenderBaseData.tender_offering_authority}</ReactMarkdown>
                                <h3>Kontaktemail:</h3>
                                <ReactMarkdown>{tenderBaseData.email_contact}</ReactMarkdown>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        </div>
    );
}

export default MetaSummaryCard;
