import { React, useState, useEffect } from "react";
import { auth } from "../Firebase/FirebaseConfig.jsx";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import styles from "./Auth.module.css";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function EmailSignupFormDialog({ openDialog, handleCloseCallback, handleLoginCallback }) {
    const [open, setOpen] = useState(openDialog);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    // Hook to open dialog
    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    // Create new user account on form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Check if passwords match
        if (password !== repeatPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await sendEmailVerification(user);
            setMessage("Account created. Please check your email for verification.");
            console.log("Verification Email sent.");
            await auth.signOut();
        } catch (err) {
            setError(err.message);
        }
    };

    // Close dialog if user clicks "Cancel"
    const handleClose = () => {
        setOpen(false);
        handleCloseCallback(false)
    };

    // Forward to login form if user clicks "I already have an account"
    const handleLogin = () => {
        setOpen(false);
        handleLoginCallback(true)
        console.log("Login clicked");
    };

    return (
        <div className={styles.SignUpDialogDiv}>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                }}
            >
                <DialogTitle>Sign up with your Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create an account with your email and password.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        required
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        required
                        margin="dense"
                        name="repeatPassword"
                        label="Repeat Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={repeatPassword} // Bind the new state variable
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                    {message && <p style={{ color: 'blue' }}>{message}</p>} {/* Display error message */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogin}>I already have an account</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Sign Up</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default EmailSignupFormDialog;
