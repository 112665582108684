import { React } from "react";
import styles from "./Footer.module.css";
import FooterLinks from "./FooterLinks";


function Footer() {

    return (
        <div className={styles.FooterDiv}>
            <FooterLinks />
        </div>
    );
};

export default Footer;
