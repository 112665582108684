import { React } from "react";
import LoadingButton from '@mui/lab/LoadingButton';

import styles from "./Search.module.css";

function SearchSubmitButton({handleSearchAuth, loading}) {
    return (
        <div className={styles.searchSubmitButtonDiv}>
            <LoadingButton
                className={styles.searchSubmitButton}
                loading={loading}
                type="submit"
                variant="outlined"
                onClick={handleSearchAuth}
            >
            {loading ? 'Analyse läuft...' : 'Ausschreibung analysieren'}
            </LoadingButton>
        </div>
        );
}

export default SearchSubmitButton;
