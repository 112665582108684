import React from 'react';
import MarkdownViewer from './MarkdownViewer';
import styles from "./Footer.module.css";


function DataAndTerms() {
  return (
    <div className={styles.DataAndTermsDiv}>
      <MarkdownViewer filePath="./datenschutz.md" />
    </div>
  );
}

export default DataAndTerms;
