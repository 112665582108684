import React, { useState } from "react"; // Added useState
import styles from "./Search.module.css";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';


function SearchBar({ onChange }) {
    const [tenderIdQuery, setTenderIdQuery] = useState(''); // Added state for query

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        width: '400%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        height: '100%',
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        width: '100%',
        height: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            [theme.breakpoints.up('sm')]: {
                width: '80%',
                '&:focus': {
                    width: '80%',
                },
            },
        },
    }));

    const handleChange = (event) => {
        setTenderIdQuery(event.target.value);
        onChange(event);
    };

    return (
        <div className={styles.searchBarDiv}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    autoFocus
                    fullWidth
                    placeholder="evergabe-online ID"
                    inputProps={{ 'aria-label': 'search' }}
                    value={tenderIdQuery} // Bind to state
                    onChange={handleChange} // Use change handler
                />
            </Search>
        </div>
    );
}

export default SearchBar;
