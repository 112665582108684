import { React } from "react";
import styles from "./Header.module.css";
import GoogleSigninButton from "../Auth/GoogleSigninButton.jsx";
import EmailSignInButton from "../Auth/EmailSignInButton.jsx";
import SignedInUserButton from "../Auth/SignedInUserButton.jsx";
import SiteMenu from "./SiteMenu.jsx";

function Header({ signedIn, userName }) {

    return (
        <div className={styles.headerContainer}>
            <div></div> {/* Empty div to create empty space on the left */}
            {/* <SiteMenu /> */}
            <div className={styles.accountDiv}>
                {!signedIn && (
                    <>
                        <div className={styles.headerEmailSigninButton}>
                            <EmailSignInButton />
                        </div>

                        <div className={styles.headerGoogleSigninButton}>
                            <GoogleSigninButton />
                        </div>
                    </>
                )}

                {signedIn && (
                    <>
                        <div className={styles.headerEmailSigninButton}>
                            <SignedInUserButton userName={userName} />
                        </div>

                    </>
                )}
            </div>


        </div>
    );
};

export default Header;
