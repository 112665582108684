import React from 'react';
import MarkdownViewer from './MarkdownViewer';
import styles from "./Footer.module.css";


function Impressum() {
  return (
    <div className={styles.DataAndTermsDiv}>
      <MarkdownViewer filePath="./impressum.md" />
    </div>
  );
}

export default Impressum;
